// TODO: Добавить типы вместо any и ts-ignore
import Vue from 'vue';
import { debounce } from 'throttle-debounce';

import { mapActions, mapMutations, mapState } from 'vuex';
import {
  DEBOUNCE_DURATION,
  MENU_TYPES,
  RESET_TYPES,
  FILTER_SELECTED_KEYS,
  MINIMAL_VALUE_LENGTH,
  WAIT_TIME_BETWEEN_MULTISELECT,
} from './options';
import {
  Menu,
  MenuItem,
  SelectedMenuItem,
  MenuTime,
  SelectedMenuTime,
  Listener,
  TSelectHandler,
  MenuValues,
  GetSelectedType,
  FetchIngredientsPayload,
  ReturnIngredientsPayload,
  SearchInputQuery,
} from './types';
// import { eventBus } from '~/containers/FilterContainer/eventBus';
import FilterSubmitButton from '~/components/filter/FilterSubmitButton.vue';
import FilterResetButton from '~/components/filter/FilterResetButton.vue';
import FilterItem from '~/components/filter/FilterItem/FilterItem.vue';

import { NOOP } from '~/utils';
import { eventAction } from '~/constants/analytics';
import {
  getPrefix,
  getSelected,
  handleTimeInputChange,
  mapSelectById,
  mapSelectedCb,
  movingTwiceElements,
  prepareData,
  prepareTwice,
  resetSelected,
  handleCuisines,
  sortedbyAlphabet,
  movingElements,
  handleIncludedIngredientsChange,
  handleExcludedIngredientsChange,
  // mapSelectedAnimationCb,
  mapSelectByIdAnimation,
  create_UUID,
  movingIngredients,
  prepareTwiceDishes,
  currentListTwice,
  sortMenuItemAlphabetic,
  sortMenuItem,
} from '~/containers/FilterContainer/pureFunctions';
import {
  ACTION_HANDLER_DESKTOP,
  ACTION_HANDLER_PHONE,
  ACTION_HANDLER_TABLET,
  FETCH_FILTERSETTINGS,
  FETCH_INGREDIENTS,
  FETCH_MENUS,
  FETCH_RECIPESCOUNT,
  SET_FALSEISFIXEDSUBMITBUTTON,
  SET_ISANIMATE,
  SET_OPENEDTAB,
  SET_RECIPESCOUNT,
  SET_TRUEISFIXEDSUBMITBUTTON,
} from '~/constants/filter';
import { TaskRunner } from '~/containers/FilterContainer/TaskRunner';
import getSlugFromUrl from '~/utils/getSlugFromUrl';

export default Vue.extend({
  name: 'FilterContainer',

  components: {
    FilterSubmitButton,
    FilterResetButton,
    FilterItem,
  },

  provide () {
    return {
      media: () => this.media,
    };
  },

  data: () => ({
    initialMenus: [],
    queuItems: [] as TSelectHandler[],
    dishes: [] as MenuItem[],
    localDishesSelectedListTwice: {
      listFirst: [] as MenuItem[],
      listLast: [] as MenuItem[],
    },
    menus: [] as MenuItem[],
    localMenusSelectedListTwice: {
      listFirst: [] as MenuItem[],
      listLast: [] as MenuItem[],
    },
    cuisines: [] as MenuItem[],
    foundCuisines: [] as MenuItem[],
    selectedCuisines: [] as SelectedMenuItem[],
    foundIncludedIngredients: [] as MenuItem[],
    selectedIncludedIngredients: [] as SelectedMenuItem[],
    foundExcludedIngredients: [] as MenuItem[],
    selectedExcludedIngredients: [] as SelectedMenuItem[],
    cookingMethods: [] as MenuItem[],
    localCookingMethodsSelectedListTwice: {
      listFirst: [] as MenuItem[],
      listLast: [] as MenuItem[],
    },
    time: [] as MenuTime[],

    cuisinesSearchQuery: '' as string,
    includedIngredientsSearchQuery: '' as string,
    excludedIngredientsSearchQuery: '' as string,

    includedIngredientsNotFound: false as boolean,
    excludedIngredientsNotFound: false as boolean,

    container_listeneres: [] as Listener[],
    phoneMedia: {} as MediaQueryList,
    tabletMedia: {} as MediaQueryList,
    desktopMedia: {} as MediaQueryList,
    taskRunner: {} as TaskRunner,
    uuid_includedIngredients: '' as string,
    uuid_excludedIngredients: '' as string,
    media: null as 'mobile' | 'tablet' | 'desktop' | null,
  }),

  async fetch (): Promise<void> {
    const menus = await this[FETCH_MENUS]();
    this.initialMenus = JSON.parse(JSON.stringify(menus));

    this.setFilterMenus(menus);

    const isFilterPage = this.$route.path.includes('filter');
    if (isFilterPage) {
      const filterSettings = await this[FETCH_FILTERSETTINGS]({ path: this.$route.path });

      this.setFilterSettings(filterSettings);

      if (this.filterUrl) {
        await this[FETCH_RECIPESCOUNT]({ url: this.filterUrl });
      }
      return;
    }

    this[SET_OPENEDTAB](MENU_TYPES.DISHES); // default tab type open
  },

  computed: {
    menuList (): Menu[] {
      return [
        {
          name: MENU_TYPES.DISHES,
          title: 'Блюда',
          selectedList: this.selectedDishes,
          openedList: this.dishes,
          openedListTwice: this.localDishesSelectedListTwice,
          search: null,
          twice: this.dishesTwice,
          animate: this.animate.dishes,
        },
        {
          name: MENU_TYPES.MENUS,
          title: 'Меню',
          selectedList: this.selectedMenus,
          openedList: this.menus,
          openedListTwice: this.localMenusSelectedListTwice,
          search: null,
          twice: true,
          animate: true,
        },
        {
          name: MENU_TYPES.CUISINES,
          title: 'Национальная кухня',
          selectedList: this.selectedCuisines,
          openedList: this.showCuisines,
          search: {
            value: this.cuisinesSearchQuery,
            handleInput: this.handleInputCuisines,
            showInstructions: false,
            notFound: !this.foundCuisines.length,
          },
          twice: false,
          animate: this.animate.cuisines,
        },
        {
          name: MENU_TYPES.INCLUDEDINGREDIENTS,
          title: 'Добавить ингредиенты',
          selectedList: this.selectedIncludedIngredients,
          openedList: this.showIncludedIngredients,
          search: {
            value: this.includedIngredientsSearchQuery,
            handleInput: this.handleInputIncludedIngredients,
            showInstructions: true,
            notFound: this.includedIngredientsNotFound,
            minValueLength: MINIMAL_VALUE_LENGTH,
          },
          twice: false,
          animate: this.animate.includedIngredients,
        },
        {
          name: MENU_TYPES.EXCLUDEDINGREDIENTS,
          title: 'Исключить ингредиенты',
          selectedList: this.selectedExcludedIngredients,
          openedList: this.showExcludedIngredients,
          search: {
            value: this.excludedIngredientsSearchQuery,
            handleInput: this.handleInputExcludedIngredients,
            showInstructions: true,
            notFound: this.excludedIngredientsNotFound,
            minValueLength: MINIMAL_VALUE_LENGTH,
          },
          twice: false,
          animate: this.animate.excludedIngredients,
        },
        {
          title: 'Способ приготовления',
          name: MENU_TYPES.COOKINGMETHODS,
          selectedList: this.selectedCookingMethods,
          openedList: this.cookingMethods,
          openedListTwice: this.localCookingMethodsSelectedListTwice,
          search: null,
          twice: true,
          animate: true,
        },

        {
          title: 'Время',
          name: MENU_TYPES.TIME,
          selectedList: this.selectedTime,
          openedList: this.time,
          search: null,
          twice: false,
          animate: false,
        },
      ];
    },

    selectedDishes (): SelectedMenuItem[] {
      return this.dishes.filter(getSelected) as SelectedMenuItem[];
    },

    selectedMenus (): SelectedMenuItem[] {
      return this.menus.filter(getSelected) as SelectedMenuItem[];
    },

    selectedCookingMethods (): SelectedMenuItem[] {
      return this.cookingMethods.filter(getSelected) as SelectedMenuItem[];
    },

    selectedTime (): SelectedMenuTime[] {
      return this.time.filter(item => (item.selected && item.slug.length)) as SelectedMenuTime[];
    },

    // url для открытия страницы с отфильтрованными рецептами
    filterUrl (): string {
      let url = '';

      FILTER_SELECTED_KEYS.forEach((type) => {
        const prefix = getPrefix(type);

        if (type !== 'selectedTime') {
          this[type].forEach((item: SelectedMenuItem | SelectedMenuTime, index: number): void => {
            url += item.slug
              ? `${(index === 0) ? prefix : ''}${item.slug}/`
              : '';
          });
        } else {
          const lastTime: SelectedMenuTime = this[type].slice(-1)[0];
          if (lastTime) {
            url += lastTime.slug
              ? `${lastTime.slug}/`
              : '';
          }
        }
      });

      return url.length ? `/recipes/filter/${url}` : url;
    },

    redirectUrl (): string {
      const taxonomyNames = ['selectedDishes', 'selectedCuisines', 'selectedCookingMethods'];

      const activeFilters =
        FILTER_SELECTED_KEYS
          .flatMap(type => this[type] as SelectedMenuItem[]);

      const activeTaxonomyFilters =
        FILTER_SELECTED_KEYS
          .filter(type => taxonomyNames.includes(type))
          .flatMap(type => this[type] as SelectedMenuItem[]);

      const isActiveOnlyTaxonomy =
        activeFilters.length === 1 &&
        activeTaxonomyFilters.length === 1;

      if (isActiveOnlyTaxonomy) {
        const { taxonomy, slug } = activeTaxonomyFilters[0] as SelectedMenuItem;
        return `/recipes/${taxonomy}/${slug}`;
      }

      return this.filterUrl;
    },

    filterCount (): number {
      // @ts-ignore
      return FILTER_SELECTED_KEYS.flatMap(type => this[type]).length;
    },

    isEditFilter (): boolean {
      return this.filterUrl.length > 0;
    },

    showCuisines (): MenuItem[] {
      // На десктопе если в поиске что-то нашли, то показываем только результаты поиска
      if (this.foundCuisines.length && this.media === 'desktop') {
        return this.foundCuisines;
      }

      const uniqueSelectedCuisines = this.selectedCuisines.filter(selectedItem =>
        !this.foundCuisines.some(item => item.id === selectedItem.id));

      const sortByTimestamp = (a: MenuItem, b: MenuItem) => {
        return (b.selectedTimestamp || 0) - (a.selectedTimestamp || 0);
      };

      // Если на мобилке или ничего не нашли, то показываем уже выбранные + потом найденные
      return [...uniqueSelectedCuisines, ...this.foundCuisines].sort(sortByTimestamp);
    },

    showIncludedIngredients (): MenuItem[] {
      if (this.includedIngredientsSearchQuery.length >= MINIMAL_VALUE_LENGTH) {
        return this.foundIncludedIngredients;
      }
      return this.selectedIncludedIngredients;
    },

    showExcludedIngredients (): SelectedMenuItem[] | MenuItem[] {
      if (this.excludedIngredientsSearchQuery.length >= MINIMAL_VALUE_LENGTH) {
        return this.foundExcludedIngredients;
      }
      return this.selectedExcludedIngredients;
    },

    debouncedSearchIngredients (): any {
      return debounce(DEBOUNCE_DURATION, async (payload: FetchIngredientsPayload) => {
        const { items, type }: ReturnIngredientsPayload = await this[FETCH_INGREDIENTS](payload);

        this.$sendAnalyticsEvent({
          event: 'Фильтры_ингредиенты',
          slug_location: getSlugFromUrl(window.location.href),
          slug_referrer: '',
          // @ts-ignore
          element: 'Поисковой запрос',
          item: payload.searchValue,
          action: type === 'included' ? 'Добавить' : 'Исключить',
          value: 1,
          currency: 'piece',
        });

        if (type === 'included') {
          this.setIncludedIngredients({ items });
          return;
        }
        this.setExcludedIngredients({ items });
      });
    },
    debounceAnimatedAll (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => {
        return this.doQueuItem(movingElements);
      });
    },
    debounceAnimated (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItem(movingElements));
    },
    debounceAnimatedTwice (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItem(movingTwiceElements));
    },
    debounceAnimatedIncludedIngredients (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItemIncludedIngredients(movingIngredients));
    },
    debounceAnimatedExcludedIngredients (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItemExcludedIngredients(movingIngredients));
    },
    debounce_FETCH_RECIPES_COUNT (): any {
      return debounce(WAIT_TIME_BETWEEN_MULTISELECT, (payload: { url: string }) => this[FETCH_RECIPESCOUNT](payload));
    },
    debounce_ACTION_HANDLER_PHONE (): any {
      return debounce(250, (payload: boolean) => {
        if (payload) { this[ACTION_HANDLER_PHONE](); }
      });
    },
    debounce_ACTION_HANDLER_TABLET (): any {
      return debounce(250, (payload: boolean) => {
        if (payload) { this[ACTION_HANDLER_TABLET](); }
      });
    },
    debounce_ACTION_HANDLER_DESKTOP (): any {
      return debounce(250, (payload: boolean) => {
        if (payload) { this[ACTION_HANDLER_DESKTOP](); }
      });
    },
    isMobileWidth (): boolean {
      return this.phoneMedia.matches || this.tabletMedia.matches;
    },
    ...mapState('filter', {
      recipesCount: 'recipesCount',
      isFixedSubmitButton: 'isFixedSubmitButton',
      openedMenu: 'openedMenu',
      animate: 'animate',
      sortDirection: 'sortDirection',
      dishesTwice: 'dishesTwice',
    }),
  },

  watch: {
    media () {
      this.updateCuisines();
    },

    filterCount (newVal: number): void {
      this.$emit('filterCount', newVal);
    },
    filterUrl (newVal: string): void {
      this.debounce_FETCH_RECIPES_COUNT({ url: newVal });
    },

    isEditFilter (newVal: boolean): void {
      if (!newVal) {
        this[SET_RECIPESCOUNT](0);
      }
    },

    includedIngredientsSearchQuery (searchValue: string): void {
      if (searchValue && (searchValue.length >= MINIMAL_VALUE_LENGTH)) {
        this.debouncedSearchIngredients({
          searchValue,
          type: 'included',
        });
      }
      // this.includedIngredientsNotFound = false;
    },

    excludedIngredientsSearchQuery (searchValue: string): void {
      if (searchValue && (searchValue.length >= MINIMAL_VALUE_LENGTH)) {
        this.debouncedSearchIngredients({
          searchValue,
          type: 'excluded',
        });
      }
      // this.excludedIngredientsNotFound = false;
    },
  },

  mounted (): void {
    this.addEventListeners();
    this.taskRunner = new TaskRunner();
  },

  destroyed () {
    this.removeEventListeners();
  },

  methods: {
    getFoundedCuisines (search: string, cuisines: MenuItem[]): MenuItem[] {
      return search === ''
        ? cuisines.concat()
        : cuisines
          .filter((item) => {
            const lowerTitle = item.title.toLocaleLowerCase();
            const lowerSearch = this.cuisinesSearchQuery.toLocaleLowerCase();

            return lowerTitle.startsWith(lowerSearch);
          });
    },

    doQueuItem (func: typeof movingElements | typeof movingTwiceElements): void {
      const vm = this;
      while (this.queuItems.length) {
        // @ts-ignore
        const { type, id, row, animateCb } = this.queuItems.shift();
        this.taskRunner.add({
          fn: (...args: any[]): Promise<any> => {
            // @ts-ignore
            const [, type, id, , cb] = args;
            // @ts-ignore
            vm[type] = mapSelectByIdAnimation(vm[type], id);
            if (func === movingElements) {
              // @ts-ignore
              let list = vm[type];

              if (row) {
                list = currentListTwice({
                  menuList: this.menuList,
                  type,
                  row,
                });
              }
              // @ts-ignore
              return func(vm, list, id, cb);
            }
            // @ts-ignore
            return func(vm, ...args);
          },
          args: [this.menuList, type, id, row, animateCb],
        });
      }
    },
    doQueuItemIncludedIngredients (func: Function): void {
      const vm = this;
      while (this.queuItems.length) {
        // @ts-ignore
        const { id, animateCb, uuid_includedIngredients } = this.queuItems.shift();
        this.taskRunner.add({
          fn: (...args: any[]): Promise<any> => {
            // @ts-ignore
            const [data, id, animateCb, uuid_includedIngredients] = args;
            const itemSelected = vm.selectedIncludedIngredients.find((item: any) => item.id === id);
            const itemFound = vm.foundIncludedIngredients.find((item: any) => item.id === id);
            if (itemSelected) {
              vm.selectedIncludedIngredients = vm.selectedIncludedIngredients.filter((item: any) => item.id !== id);
            } else {
              // @ts-ignore
              vm.selectedIncludedIngredients.unshift({ ...itemFound, selected: true });
            }
            // @ts-ignore
            itemFound.selected = !itemFound.selected;
            // @ts-ignore
            itemFound.isLockAnimation = false;
            // @ts-ignore
            return func(vm, data, id, animateCb, uuid_includedIngredients === itemFound.uuid);
          },
          args: [vm.foundIncludedIngredients, id, animateCb, uuid_includedIngredients],
        });
      }
    },
    doQueuItemExcludedIngredients (func: Function): void {
      const vm = this;
      while (this.queuItems.length) {
        // @ts-ignore
        const { id, animateCb, uuid_excludedIngredients } = this.queuItems.shift();
        this.taskRunner.add({
          fn: (...args: any[]): Promise<any> => {
            // @ts-ignore
            const [data, id, animateCb, uuid_excludedIngredients] = args;
            const itemSelected = vm.selectedExcludedIngredients.find((item: any) => item.id === id);
            const itemFound = vm.foundExcludedIngredients.find((item: any) => item.id === id);
            if (itemSelected) {
              vm.selectedExcludedIngredients = vm.selectedExcludedIngredients.filter((item: any) => item.id !== id);
            } else {
              // @ts-ignore
              vm.selectedExcludedIngredients.unshift({ ...itemFound, selected: true });
            }
            // @ts-ignore
            itemFound.selected = !itemFound.selected;
            // @ts-ignore
            itemFound.isLockAnimation = false;
            // @ts-ignore
            return func(this, data, id, animateCb, uuid_excludedIngredients === itemFound.uuid);
          },
          args: [vm.foundExcludedIngredients, id, animateCb, uuid_excludedIngredients],
        });
      }
    },
    NOOP,
    getRecipesCountText (): string {
      const result = (this.recipesCount > 9999) ? '9999+' : this.recipesCount;
      return result ? `(${result})` : '';
    },
    setFilterMenus (menuProp: any): void {
      if (!menuProp) {
        return;
      }

      const {
        cuisines,
        cookingMethods,
        menus,
        dishes,
        time,
      } = menuProp;

      this.cuisines = prepareData(cuisines);
      this.foundCuisines = this.cuisines.concat();
      this.cookingMethods = prepareData(cookingMethods);
      prepareTwice(this.localCookingMethodsSelectedListTwice, this.cookingMethods);
      this.menus = prepareData(menus);
      prepareTwice(this.localMenusSelectedListTwice, this.menus);
      const sorted = sortedbyAlphabet(dishes);
      this.dishes = prepareData(sorted);
      prepareTwiceDishes(this.localDishesSelectedListTwice, this.dishes);
      // @ts-ignore
      this.time = prepareData(time);
    },

    /* eslint-disable camelcase */
    // установка параметров фильтра при старте приложения на
    // странице с отфильтрованными результатами
    setFilterSettings (data: any): void {
      if (!data) {
        return;
      }

      const {
        cuisines,
        cookingMethods,
        menus,
        dishes,
        time,
        includeIngredients,
        excludeIngredients,
      } = data;

      if (time) {
        const indexTime = this.time.findIndex(item => item.id === time);

        this.time = this.time.map((item, index) => ({
          ...item,
          selected: indexTime <= index,
        }));
      }

      this.selectedIncludedIngredients.push(...includeIngredients);
      this.selectedIncludedIngredients = this.selectedIncludedIngredients.map(mapSelectedCb(true));
      this.selectedExcludedIngredients.push(...excludeIngredients);
      this.selectedExcludedIngredients = this.selectedExcludedIngredients.map(mapSelectedCb(true));

      cuisines.forEach((id: string) => {
        /* eslint-disable-next-line */
        this.cuisines = mapSelectById(this, this.cuisines, id, true);
      });

      this.selectedCuisines = cuisines.map((id: string) => {
        const defaultSelectedCuisine = this.cuisines.find((cuisine: MenuItem) => cuisine.id === id);

        return {
          ...defaultSelectedCuisine,
          selected: true,
        };
      });

      cookingMethods.forEach((id: string) => {
        this.cookingMethods = mapSelectById(this, this.cookingMethods, id, true);
      });

      menus.forEach((id: string) => {
        this.menus = mapSelectById(this, this.menus, id, true);
      });

      dishes.forEach((id: string) => {
        this.dishes = mapSelectById(this, this.dishes, id, true);
      });
    },
    setExcludedIngredients ({ items }: { items: MenuItem[] }): void {
      const uuid = create_UUID();
      this.uuid_excludedIngredients = uuid;
      const preparedItems = prepareData(items).map(el => ({ ...el, uuid })) as SelectedMenuItem[];
      this.excludedIngredientsNotFound = !preparedItems.length;
      const selectedIds = this.selectedExcludedIngredients.map(el => el.id);
      const filtered = preparedItems.filter(item => !selectedIds.includes(item.id));
      if (filtered.length === 0) {
        // this.foundExcludedIngredients = filtered;
        this.foundExcludedIngredients = this.sortDirection === 'asc'
          ? filtered.concat(this.selectedExcludedIngredients)
          : (this.selectedExcludedIngredients as MenuItem[]).concat(filtered);

        return;
      }
      this.foundExcludedIngredients = this.media === 'desktop'
        ? filtered
        : (this.selectedExcludedIngredients as MenuItem[]).concat(filtered);
      // this.sortDirection === 'asc'
      //   ? filtered.concat(this.selectedExcludedIngredients)
      //   : (this.selectedExcludedIngredients as MenuItem[]).concat(filtered);
    },
    setIncludedIngredients ({ items }: { items: MenuItem[]}): void {
      const uuid = create_UUID();
      this.uuid_includedIngredients = uuid;
      const preparedItems = prepareData(items).map(el => ({ ...el, uuid })) as SelectedMenuItem[];
      this.includedIngredientsNotFound = !preparedItems.length;
      const selectedIds = this.selectedIncludedIngredients.map(el => el.id);
      const filtered = preparedItems.filter(item => !selectedIds.includes(item.id));

      if (filtered.length === 0) {
        // this.foundIncludedIngredients = filtered;
        this.foundIncludedIngredients = this.sortDirection === 'asc'
          ? filtered.concat(this.selectedIncludedIngredients)
          : (this.selectedIncludedIngredients as MenuItem[]).concat(filtered);

        return;
      }

      this.foundIncludedIngredients = this.media === 'desktop'
        ? filtered
        : (this.selectedIncludedIngredients as MenuItem[]).concat(filtered);
      // this.sortDirection === 'asc'
      //   ? filtered.concat(this.selectedIncludedIngredients)
      //   : (this.selectedIncludedIngredients as MenuItem[]).concat(filtered);
    },
    // deprecated
    setCuisines (items: MenuItem[]): void {
      const selectedIds = this.selectedCuisines.map(el => el.id);
      const filtered = items
        .filter(item => !selectedIds.includes(item.id));

      if (filtered.length === 0) {
        this.foundCuisines = filtered;
        return;
      }

      this.foundCuisines = this.cuisinesSearchQuery.length
        ? filtered
        : (<MenuItem[]> this.selectedCuisines).concat(filtered);

      // ???
      // this.sortDirection === 'asc'
      //   ? filtered.concat(<MenuItem[]> this.selectedCuisines)
      //   : (<MenuItem[]> this.selectedCuisines).concat(filtered);
    },
    findMenuListByName (type: string) {
      return this.menuList.find(el => el.name === type);
    },

    select (options: TSelectHandler, isSendAnalytics: boolean = true): void {
      this.$emit('used-filter');
      const menu = this.findMenuListByName(options.type);
      if (!menu) {
        return;
      }
      const condition = typeof options.animateCb === 'function' && menu.animate;
      condition
        ? this.selectHaveAnimate(options)
        : this.selectNoAnimate(options, isSendAnalytics);
    },

    selectHaveAnimate (options: TSelectHandler): void {
      const { type, id } = options;
      if ([MENU_TYPES.MENUS, MENU_TYPES.COOKINGMETHODS, MENU_TYPES.DISHES].find(el => el === type)) {
        const menu = this.findMenuListByName(type);
        if (!menu) {
          return;
        }
        this.queuItems.push(options);
        this.debounceAnimatedAll();
        // const { twice } = menu;
        // twice ? this.debounceAnimatedTwice() : this.debounceAnimated();
      } else if (type === MENU_TYPES.CUISINES) {
        handleCuisines(this, id);
      } else if (type === MENU_TYPES.INCLUDEDINGREDIENTS) {
        this.queuItems.push({ ...options, uuid_includedIngredients: this.uuid_includedIngredients });
        this.debounceAnimatedIncludedIngredients();
      } else if (type === MENU_TYPES.EXCLUDEDINGREDIENTS) {
        this.queuItems.push({ ...options, uuid_excludedIngredients: this.uuid_excludedIngredients });
        this.debounceAnimatedExcludedIngredients();
      } else if (type === MENU_TYPES.TIME) {
        this.time = handleTimeInputChange(this, id);
      }
    },
    selectNoAnimate (options: TSelectHandler, isSendAnalytics: boolean = true) {
      const { type, id } = options;
      if ([MENU_TYPES.MENUS, MENU_TYPES.COOKINGMETHODS, MENU_TYPES.DISHES].find(el => el === type)) {
        // @ts-ignore
        this[type] = mapSelectById(this, this[type], id, undefined, isSendAnalytics);
      } else if (type === MENU_TYPES.CUISINES) {
        handleCuisines(this, id, false);
      } else if (type === MENU_TYPES.INCLUDEDINGREDIENTS) {
        handleIncludedIngredientsChange(this, id, false);
      } else if (type === MENU_TYPES.EXCLUDEDINGREDIENTS) {
        handleExcludedIngredientsChange(this, id, false);
      } else if (type === MENU_TYPES.TIME) {
        this.time = handleTimeInputChange(this, id);
      }
    },
    reset () {
      this.$sendAnalyticsEvent({
        event: 'Фильтры_взаимодействие',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: '',
        element: '',
        item: '',
        action: 'Очистить фильтры',
        value: 1,
        currency: 'piece',
      });
      if (this.isMobileWidth) {
        this.resetFilterTablet();
        return;
      }
      this.resetFilter();
    },
    resetFilterTablet (): void {
      // const counter = 0;
      // this[SET_ISANIMATE](false);
      // eventBus.$once('taskRunner:finish', () => {
      //   this[SET_ISANIMATE](true);
      // });
      this.selectedCuisines = [];
      this.selectedIncludedIngredients = [];
      this.selectedExcludedIngredients = [];
      this.foundIncludedIngredients = [];
      this.foundExcludedIngredients = [];
      this.resetSearchQuery();
      this.menuList
        .filter(el => (el.name === MENU_TYPES.DISHES) && !el.twice)
        .forEach((menuItem) => {
          (menuItem.openedList as any[])
            ?.filter((el): boolean => {
              return !!el.selected;
            })
            .forEach((el: MenuItem | MenuTime) => {
              // el.activatedHandler = true;
              el.selected = false;
              el.selectedTimestamp = null;
            });
          (menuItem.openedList as any[])
            .sort(sortMenuItemAlphabetic());
        });
      this.menuList
        .filter(el => el.twice)
        .forEach((menuItem) => {
          (menuItem.openedListTwice?.listFirst as any[])
            .filter(el => el.selected)
            .forEach((el: MenuItem | MenuTime) => {
              // el.activatedHandler = true;
              el.selected = false;
              el.selectedTimestamp = null;
            });
          (menuItem.openedListTwice?.listFirst as any[])
            .sort(sortMenuItemAlphabetic());

          (menuItem.openedListTwice?.listLast as any[])
            .filter(el => el.selected)
            .forEach((el: MenuItem | MenuTime) => {
              // el.activatedHandler = true;
              el.selected = false;
              el.selectedTimestamp = null;
            });
          (menuItem.openedListTwice?.listLast as any[])
            .sort(sortMenuItemAlphabetic());
        });
      ['time'].forEach((type) => {
        // @ts-ignore
        const item = this[type];
        // @ts-ignore
        this[type] = item.map(resetSelected);
      });
      // this.foundCuisines = this.cuisines.map(mapSelectedAnimationCb(false));
      this.foundCuisines = this.cuisines
        .map((item: MenuItem) => {
          item.selected = false;
          item.selectedTimestamp = null;
          item.isLockAnimation = false;
          return item;
        })
        .sort(sortMenuItemAlphabetic());

      // if (counter === 0) {
      //   this[SET_ISANIMATE](true);
      // }

      this.setFilterMenus(this.initialMenus);
    },
    resetFilter (): void {
      this.menuList.filter(el => el.twice)
        .forEach((menuItem) => {
          (menuItem.openedListTwice?.listFirst as any[])
            .filter((el): boolean => {
              return !!el.selected;
            })
            .forEach((el: MenuItem | MenuTime) => {
              this.select({ type: menuItem.name, id: el.id, row: 'first' }, false);
            });
          (menuItem.openedListTwice?.listLast as any[])
            .filter(el => el.selected)
            .forEach((el: MenuItem | MenuTime) => {
              this.select({ type: menuItem.name, id: el.id, row: 'last' }, false);
            });
        });
      (this.menuList.find(el => el.name === MENU_TYPES.DISHES)?.openedList as any[])
        ?.filter(el => el.selected)
        .forEach((el: MenuItem | MenuTime) => {
          this.select({ type: MENU_TYPES.DISHES, id: el.id }, false);
        });
      RESET_TYPES.forEach((type) => {
        const item = this[type];
        // @ts-ignore
        this[type] = item.map(resetSelected);
      });

      this.selectedCuisines = [];
      this.selectedIncludedIngredients = [];
      this.selectedExcludedIngredients = [];
      this.foundIncludedIngredients = [];
      this.foundExcludedIngredients = [];

      /* eslint-disable-next-line */
      this.cuisines = this.cuisines.map(mapSelectedCb(false));

      this.resetSearchQuery();

      this.$emit('used-filter');
    },

    resetSearchQuery (type?: SearchInputQuery): void {
      switch (type) {
        case 'cuisines':
          this.cuisinesSearchQuery = '';
          this.updateCuisines();
          return;
        case 'includedIngredients':
          this.includedIngredientsSearchQuery = '';
          return;
        case 'excludedIngredients':
          this.excludedIngredientsSearchQuery = '';
          return;
        default:
          this.cuisinesSearchQuery = '';
          this.updateCuisines();
          this.includedIngredientsSearchQuery = '';
          this.excludedIngredientsSearchQuery = '';
      }
    },

    handleInputCuisines (event: InputEvent): void {
      this.cuisinesSearchQuery = (event.target as HTMLInputElement).value;
      this.updateCuisines();
    },

    updateCuisines () {
      // const foundedItems = this.cuisinesSearchQuery === ''
      //   ? this.cuisines.concat()
      //   : this.cuisines
      //     .filter((item) => {
      //       const lowerTitle = item.title.toLocaleLowerCase();
      //       const lowerSearch = this.cuisinesSearchQuery.toLocaleLowerCase();

      //       return this.media !== 'desktop'
      //         ? lowerTitle.startsWith(lowerSearch) || this.selectedCuisines.find((selectedItem: MenuItem) => selectedItem.id === item.id)
      //         : lowerTitle.startsWith(lowerSearch);
      //     });

      const foundedItems = this.getFoundedCuisines(this.cuisinesSearchQuery, this.cuisines);

      if (this.media !== 'desktop') {
        foundedItems.sort(sortMenuItem());
      }

      this.foundCuisines = foundedItems;

      // this.setCuisines(foundedItems);
    },
    handleInputIncludedIngredients (event: InputEvent): void {
      this.includedIngredientsSearchQuery = (event.target as HTMLInputElement).value;
    },

    handleInputExcludedIngredients (event: InputEvent): void {
      this.excludedIngredientsSearchQuery = (event.target as HTMLInputElement).value;
    },

    updateOpenedMenu (value: MenuValues | '', label?: string): void {
      if (this.isMobileWidth) {
        return;
      }

      this.foundIncludedIngredients = [];
      this.foundExcludedIngredients = [];

      const isAlreadyOpen = (this.openedMenu === value);
      this[SET_OPENEDTAB](isAlreadyOpen ? '' : value);

      if (value === 'cuisines') {
        this.media === 'desktop'
          ? this.selectedCuisines.sort(sortMenuItemAlphabetic())
          : this.selectedCuisines.sort(sortMenuItem());
      }

      this.resetSearchQuery();

      this.$emit('used-filter');

      if (label) {
        const action = isAlreadyOpen ? eventAction.compressionFilters : eventAction.openingFilters;
        this.$sendAnalyticsEvent({
          event: 'Фильтры взаимодействие',
          slug_location: getSlugFromUrl(this.$route.fullPath),
          slug_referrer: '',
          element: label,
          item: '',
          action,
          value: 1,
          currency: 'piece',
        });
      }
    },

    async handleSubmit (): Promise<void> {
      if (this.isEditFilter && this.recipesCount as number) {
        this.sendEventToGaWhenSubmit();
        await this.$nextTick();
        location.href = this.redirectUrl;
        this.updateOpenedMenu('');
        this.resetSearchQuery();
        this.$emit('update-url');
        this.$emit('used-filter');
      }
    },

    sendEventToGaWhenSubmit (): void {
      this.$sendAnalyticsEvent({
        event: 'Фильтры_подобрать рецепт',
        slug_location: getSlugFromUrl(this.$route.fullPath),
        slug_referrer: this.filterUrl,
        element: '',
        item: '',
        action: '',
        value: 1,
        currency: 'piece',
      });
    },

    getSelectedFilterMenusItems (): GetSelectedType {
      const selectedItems: GetSelectedType = {};
      FILTER_SELECTED_KEYS.forEach((type) => {
        const typeItem = this[type];
        if (typeItem.length) {
          selectedItems[type] = [] as SelectedMenuItem[] | SelectedMenuTime[];
          typeItem.forEach((item: SelectedMenuItem | SelectedMenuTime) => {
            selectedItems[type]?.push(item.slug);
          });
        }
      });
      return selectedItems;
    },

    removeEventListeners (): void {
      while (this.container_listeneres.length > 0) {
        const item = this.container_listeneres.shift();
        item?.el.removeEventListener('change', item?.handler);
      }
    },

    addEventListeners (): void {
      const handlerPhone = (e: MediaQueryList| MediaQueryListEvent): void => {
        this.debounce_ACTION_HANDLER_PHONE(e.matches);
        if (e.matches) {
          this.media = 'mobile';
        }
      };
      const phoneMedia = window.matchMedia('(max-width: 767px)');
      this.phoneMedia = phoneMedia;
      handlerPhone(phoneMedia);
      phoneMedia.addEventListener('change', handlerPhone);
      const handlerTablet = (e: MediaQueryList | MediaQueryListEvent): void => {
        this.debounce_ACTION_HANDLER_TABLET(e.matches);
        if (e.matches) {
          this.media = 'tablet';
        }
      };
      const tabletMedia = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
      this.tabletMedia = tabletMedia;
      handlerTablet(tabletMedia);
      tabletMedia.addEventListener('change', handlerTablet);

      const handlerDesktop = (e: MediaQueryList | MediaQueryListEvent): void => {
        this.debounce_ACTION_HANDLER_DESKTOP(e.matches);
        if (e.matches) {
          this.media = 'desktop';
        }
      };
      const desktopMedia = window.matchMedia('(min-width: 1024px)');
      this.desktopMedia = desktopMedia;
      handlerDesktop(desktopMedia);
      desktopMedia.addEventListener('change', handlerDesktop);

      this.container_listeneres.push(
        { el: phoneMedia, handler: handlerPhone },
        { el: tabletMedia, handler: handlerTablet },
        { el: desktopMedia, handler: handlerDesktop },
      );
    },
    ...mapMutations('filter', [
      SET_RECIPESCOUNT,
      SET_TRUEISFIXEDSUBMITBUTTON,
      SET_FALSEISFIXEDSUBMITBUTTON,
      SET_OPENEDTAB,
      SET_ISANIMATE,
    ]),
    ...mapActions('filter', [
      FETCH_MENUS,
      FETCH_FILTERSETTINGS,
      FETCH_RECIPESCOUNT,
      FETCH_INGREDIENTS,
      ACTION_HANDLER_PHONE,
      ACTION_HANDLER_TABLET,
      ACTION_HANDLER_DESKTOP,
    ]),
  },
});
